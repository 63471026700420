import { Pipe, PipeTransform } from '@angular/core';

import { NavMenuSubItem } from '../../types/nav-menu-item.types';

@Pipe({
  name: 'breakOnColumns',
  standalone: true,
})
export class BreakOnColumnsPipe implements PipeTransform {
  transform(
    items: NavMenuSubItem[],
    columnsNumber?: number
  ): NavMenuSubItem[][] {
    if (!columnsNumber || columnsNumber < 1) {
      console.warn("columnsNumber should be defined and can't be less than 1");
      return [items];
    }
    const totalSymbols = items.reduce(
      (acc, item) => acc + item.title.length,
      0
    );
    const maxSymbolsInColumn = totalSymbols / columnsNumber;
    const columns: NavMenuSubItem[][] = Array.from(Array(columnsNumber)).map(
      () => []
    );
    let columnId = 0;
    let currentSum = 0;
    let i = 0;
    while (i < items.length) {
      const item = items[i];
      currentSum += item.title.length;
      columns[columnId].push(item);
      if (currentSum >= maxSymbolsInColumn && columnId < columnsNumber - 1) {
        columnId++;
        currentSum = 0;
      }
      i++;
    }
    return columns;
  }
}
