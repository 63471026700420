import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MoreButtonComponent } from './more-button/more-button.component';
import { MoreBlockInfo, NavMenuSubItem } from '../../types/nav-menu-item.types';
import { BreakOnColumnsPipe } from './break-on-columns.pipe';

@Component({
  selector: 'app-nav-menu-item-with-dropdown',
  templateUrl: './nav-menu-item-with-dropdown.component.html',
  styleUrls: ['./nav-menu-item-with-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MoreButtonComponent,
    BreakOnColumnsPipe,
  ],
})
export class NavMenuItemWithDropdownComponent {
  @Input() title = '';
  @Input() link = '';
  @Input() testId = '';
  @Input() items: NavMenuSubItem[] = [];
  @Input() itemColumns?: number;
  @Input() moreInfo?: MoreBlockInfo;
  @Input() alignRight? = false;
  @Input() vpWidth?: number;
  @Input() minHeightPx?: number;

  isInteracted = false;

  itemClick(item: NavMenuSubItem): void {
    if (item.click) {
      item.click();
    }
    this.toggleInteraction(true);
  }

  toggleInteraction(isInteracted: boolean): void {
    this.isInteracted = isInteracted;
  }
}
