<div class="dropdown-container"
  (mouseenter)="toggleInteraction(false)"
  [class.dropdown-hidden]="isInteracted">
  <!-- Пункт навигационного меню -->
  <span
    class="dropdown-trigger typography typography-headline typography-medium grey-800"
  >
    <a *ngIf="link; else noLink"
      [routerLink]="link"
      (click)="toggleInteraction(true)"
      [attr.data-testid]="testId">
      {{ title }}
    </a>
    <ng-template #noLink>
      <span [attr.data-testid]="testId">
        {{ title }}
      </span>
    </ng-template>
  </span>

  <!-- Выпадающее меню -->
  <div class="dropdown dropdown-menu grid"
    [class.right-aligned]="alignRight"
    [ngStyle]="{ width: vpWidth + 'vw' }">
    <!-- Блок с пунктами выпадающего меню -->
    <div class="grid-item grid items" [ngClass]="{ 'columns-3': itemColumns === 3, 'columns-2': itemColumns === 2 }"
      [ngStyle]="{ 'min-height': minHeightPx ? minHeightPx + 'px' : 'min-content' }">
      <div class="grid-item grid grid-column" *ngFor="let columnItems of (items | breakOnColumns:itemColumns)">
        <a
          *ngFor="let item of columnItems"
          class="grid-item typography typography-headline item"
          [routerLink]="item.click ? [] : item.link"
          (click)="itemClick(item)"
          [attr.data-testid]="item.testId"
        >
          {{ item.title }}
        </a>
      </div>
    </div>
    <!-- Блок справа, с картинкой и кнопкой -->
    <div *ngIf="moreInfo"
      class="grid-item grid grid-column justify-content-between more">
      <div class="blured-ellipsis top-left"></div>
      <div class="blured-ellipsis bottom-right"></div>
      <div class="grid-item grid grid-column">
        <div class="grid-item typography typography-h3 typography-bold gray-800">
          {{ moreInfo.title }}
        </div>
        <div class="grid-item typography typography-body gray-800">
          {{ moreInfo.subtitle }}
        </div>
      </div>
      <div class="grid-item typography">
        <a [routerLink]="moreInfo.moreButtonUrl" (click)="toggleInteraction(true)">
          <app-more-button [text]="moreInfo.moreButtonText"></app-more-button>
        </a>
      </div>
      <div class="corner-image" [ngStyle]="{ 'background-image': 'url(\'' + moreInfo.cornerImageUrl + '\')' }"></div>
    </div>
  </div>
</div>