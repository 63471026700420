import { inject, Injectable } from '@angular/core';
import { PlatformService } from '@shared/services/platform.service';
import { YmHitOptions } from '@shared/services/yandex-metrica/interfaces/ym-hit.interfaces';
import { YmReachGoalOptions } from '@shared/services/yandex-metrica/interfaces/ym-reach-goal.interfaces';

@Injectable()
export class YmService {
  private platform = inject(PlatformService);

  private ym(type: 'hit', url?: string, options?: Partial<YmHitOptions>): void;
  private ym(
    type: 'reachGoal',
    target: string,
    options: Partial<YmReachGoalOptions> | undefined
  ): void;

  private ym(...args: any): void {
    if (this.platform.isServer) return;

    const idCounter: number | undefined = window.ym?.a[0][0];
    if (idCounter) {
      window.ym(idCounter, ...args);
    }
  }

  hit(): void {
    this.ym('hit');
  }

  reachGoal(target: string, options?: Partial<YmReachGoalOptions>): void {
    this.ym('reachGoal', target, options);
  }
}
