import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-more-button',
  templateUrl: './more-button.component.html',
  styleUrls: ['./more-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class MoreButtonComponent {
  @Input() text = 'Больше';
}
